import $ from '../core/Dom';

export default el => {
    const $el = $(el);

    const init = () => {
        const $button = $el.find('button.enupal-stripe-button');
        
        if ($button.length > 0) {
            setTimeout(() => {
                $button.get(0).click();
            }, 1500);
        }
    };

    const destroy = () => {
        
    };
    
    return {
        init,
        destroy
    };
};
