import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import gsap from 'gsap';

import { MENU_OPEN, MENU_CLOSE, SEARCH_OPEN, SEARCH_CLOSE } from '../lib/events';

export default el => {
    const PANEL_SLIM_BP = 750;

    const $el = $(el);
    const $body = $('body');

    const $bar = $el.find('[data-header-bar]');
    
    const $menuToggle = $el.find('[data-menu-toggle]');
    const $menu = $el.find('#menu');
    const $menuInner = $el.find('[data-menu-inner]');
    const $searchToggle = $el.find('[data-search-toggle]');
    const $search = $el.find('#search');
    const $searchInner = $el.find('[data-search-inner]');
    const $darkModeToggle = $el.find('[data-darkmode-toggle]');
    
    const $panelsBg = $el.find('[data-panels-bg]');

    let breakpoint = Viewport.breakpoint.size;
    
    let menuOpen = false;
    let searchOpen = false;

    let isBarCollapsed = false;
    let isBarHidden = false;
    let hiddenThreshold;
    let collapsedThreshold;
    let currentScrollTop = Viewport.scrollTop;
    let toggleTimeline = null;
    let enableAnimationsTimeout = -1;

    const disableAnimations = () => {
        $body.addClass('disable-animations');
    };
    
    const enableAnimations = () => {
        clearTimeout(enableAnimationsTimeout);
        enableAnimationsTimeout = setTimeout(() => {
            $body.removeClass('disable-animations');
        }, 100);
    };
    
    const closeMenu = (tween = true) => {
        if (tween) {
            gsap.to($menu.nodes, {
                duration: 0.2,
                height: '0%',
                ease: 'power1.in'
            });
    
            gsap.to($menuInner.nodes, {
                duration: 0.05,
                opacity: 0
            });
    
            gsap.to($panelsBg.nodes, {
                duration: 0.2, 
                opacity: 0, 
                onComplete: () => {
                    $panelsBg.css({ display: 'none' });
                }
            });
        } else {
            $menu.css({ height: '0%' });
            $menuInner.css({ opacity: 0 });
            $panelsBg.css({ display: 'none' });
        }
        
        menuOpen = false;
    };

    const openMenu = (tween = true) => {
        const targetHeight = breakpoint >= PANEL_SLIM_BP ? 'auto' : '100%';
        
        if (tween) {
            $menuInner.css({ opacity: 0 });
            $panelsBg.css({ display: 'block', opacity: 0 });
    
            gsap.to($menu.nodes, {
                duration: 0.8,
                height: targetHeight,
                ease: 'quint.out'
            });
            
            gsap.to($menuInner.nodes, {
                duration: 0.6,
                delay: 0.2,
                opacity: 1
            });
            
            gsap.to($panelsBg.nodes, {
                duration: 0.4, 
                opacity: 1
            });
        } else {
            $menu.css({ height: targetHeight });
            $menuInner.css({ opacity: 1 });
            $panelsBg.css({ display: 'block', opacity: 1 });
        }
        
        menuOpen = true;
    };    

    const closeSearch = (tween = true) => {
        if (tween) {
            gsap.to($search.nodes, {
                duration: 0.2,
                height: '0%',
                ease: 'power1.in'
            });
    
            gsap.to($searchInner.nodes, {
                duration: 0.05,
                opacity: 0
            });
    
            gsap.to($panelsBg.nodes, {
                duration: 0.2, 
                opacity: 0, 
                onComplete: () => {
                    $panelsBg.css({ display: 'none' });
                }
            });
        } else {
            $search.css({ height: '0%' });
            $searchInner.css({ opacity: 0 });
            $panelsBg.css({ display: 'none' });
        }
        
        searchOpen = false;
    };
    
    const openSearch = (tween = true) => {
        const targetHeight = breakpoint >= PANEL_SLIM_BP ? 'auto' : '100%';
        
        if (tween) {
            $searchInner.css({ opacity: 0 });
            $panelsBg.css({ display: 'block', opacity: 0 });
    
            gsap.to($search.nodes, {
                duration: 0.8,
                height: targetHeight,
                ease: 'quint.out'
            });
            
            gsap.to($searchInner.nodes, {
                duration: 0.6,
                delay: 0.2,
                opacity: 1
            });
            
            gsap.to($panelsBg.nodes, {
                duration: 0.4, 
                opacity: 1
            });
        } else {
            $search.css({ height: targetHeight });
            $searchInner.css({ opacity: 1 });
            $panelsBg.css({ display: 'block', opacity: 1 });
        }
        
        searchOpen = true;
    };

    const hideBar = (tween = true) => {
        if (isBarHidden || menuOpen) {
            return;
        }
        
        if (!tween) {
            disableAnimations();
        }
        
        isBarHidden = true;
        $el.addClass('is-hidden');
        enableAnimations();
    };

    const showBar = (tween = true) => {
        if (!isBarHidden) {
            return;
        }
        
        if (!tween) {
            disableAnimations();
        }
        
        isBarHidden = false;
        $el.removeClass('is-hidden');
        enableAnimations();
    };

    const collapseBar = (tween = true) => {
        if (isBarCollapsed) {
            return;
        }
        
        if (!tween) {
            disableAnimations();
        }
        
        isBarCollapsed = true;
        $el.addClass('is-collapsed');
        enableAnimations();
    };

    const expandBar = (tween = true) => {
        if (!isBarCollapsed || menuOpen) {
            return;
        }
        
        if (!tween) {
            disableAnimations();
        }
        
        isBarCollapsed = false;
        $el.removeClass('is-collapsed');
        enableAnimations();
    };

    const evaluateState = () => {
        const { scrollTop } = Viewport;
        
        if (scrollTop < collapsedThreshold) {
            expandBar();
        } else {
            collapseBar();
        }
    };
    
    const animateMenuToggle = () => {
        if (menuOpen) {
            toggleTimeline.play();
        } else {
            toggleTimeline.reverse();
        }
    };
    
    const createToggleTimeline = () => {
        const $spans = $menuToggle.find('span');
        const $centerSpan = $($spans.get(1));
        
        toggleTimeline = gsap.timeline({});
        toggleTimeline.to($spans.get(0), { duration: 0.3, top: $centerSpan.css('top'), ease: 'power1.in' }, 0.4);
        toggleTimeline.to($spans.get(2), { duration: 0.3, top: $centerSpan.css('top'), ease: 'power1.in' }, 0.4);
        toggleTimeline.to($spans.get(1), { duration: 0, opacity: 0 }, 0.7);
        toggleTimeline.to($spans.get(0), { duration: 0.4, rotate: -45, ease: 'power2.out' }, 0.7);
        toggleTimeline.to($spans.get(2), { duration: 0.4, rotate: 45, ease: 'power2.out' }, 0.7);
        
        toggleTimeline.pause();
    };

    const onMenuToggle = e => {
        e.preventDefault();
        
        if (menuOpen) {
            Dispatch.emit(MENU_CLOSE);
        } else {
            Dispatch.emit(MENU_OPEN);
        }
    };

    const onMenuOpen = () => {
        openMenu();
        showBar();
        collapseBar();
        animateMenuToggle();
    };

    const onMenuClosed = () => {
        closeMenu();
        evaluateState();
        animateMenuToggle();
    };

    const onSearchToggle = e => {
        e.preventDefault();
        
        if (searchOpen) {
            Dispatch.emit(SEARCH_CLOSE);
        } else {
            Dispatch.emit(SEARCH_OPEN);
        }
    };

    const onSearchOpen = () => {
        openSearch();
        showBar();
        collapseBar();
        //animateMenuToggle();
    };

    const onSearchClosed = () => {
        closeSearch();
        evaluateState();
        //animateMenuToggle();
    };
    
    const onBgClick = () => {
        if (menuOpen) {
            onMenuClosed();
        }
        if (searchOpen) {
            onSearchClosed();
        }
    };

    const onBreakpoint = () => {
        /*
        const wasSmall = isSmall(breakpoint);
        if (wasSmall !== isSmall()) {
            closeMenu(false);
        }
        *
         */
        breakpoint = Viewport.breakpoint.name;
    };

    const onBodyKeyUp = e => {
        if (!menuOpen) {
            return;
        }
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            Dispatch.emit(MENU_CLOSE);
        }
    };

    const onScroll = () => {
        const { scrollTop } = Viewport;
        if (Math.abs(scrollTop - currentScrollTop) < 5) {
            return;
        }

        if (scrollTop < hiddenThreshold) {
            showBar();
        } else {
            const direction = scrollTop > currentScrollTop ? 'down' : 'up';
            if (direction === 'down') {
                hideBar();
            } else {
                showBar();
            }
        }

        if (scrollTop < collapsedThreshold) {
            expandBar();
        } else {
            collapseBar();
        }

        currentScrollTop = scrollTop;
    };

    const onInnerFocus = e => {
        /*
        const { triggerTarget: target } = e;
        if ($(target).parent('[data-shortcuts-menu]').get(0)) {
            return;
        }   
         */
        showBar();
    };

    const onResize = () => {
        collapsedThreshold = 30;
        hiddenThreshold = 30;

        if (!isBarHidden) {
            return;
        }

        requestAnimationFrame(() => {
            const { scrollTop } = Viewport;
            if (scrollTop < hiddenThreshold) {
                showBar();
            }
            if (scrollTop < collapsedThreshold) {
                expandBar();
            }
        });
    };
    
    const onDarkModeToggle = () => {
        const $html = $('html');
        
        if ($html.hasClass('dark')) {
            $html.removeClass('dark');
            localStorage.theme = 'light';
        } else {
            $html.addClass('dark');
            localStorage.theme = 'dark';
        }
    };

    const init = () => {
        createToggleTimeline();
        
        const navId = $menuToggle.attr('href');

        $menuToggle.on('click', onMenuToggle);
        $searchToggle.on('click', onSearchToggle);
        $darkModeToggle.on('click', onDarkModeToggle);
        $panelsBg.on('click', onBgClick);

        $('body').on('keyup', onBodyKeyUp);

        Viewport.on('breakpoint', onBreakpoint);
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);

        $el.on('focusin', 'a,button', onInnerFocus);

        Dispatch.on(MENU_OPEN, onMenuOpen);
        Dispatch.on(MENU_CLOSE, onMenuClosed);
        Dispatch.on(SEARCH_OPEN, onSearchOpen);
        Dispatch.on(SEARCH_CLOSE, onSearchClosed);
        
        if ((window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0) > 0) {
            hideBar(false);
            collapseBar(false);
        }

        onResize();
        
        $bar.css({ opacity: 1 });

        // Account for the menu being opened already before the JS had the chance to boot
        requestAnimationFrame(() => {
            if (navId && window.location.hash === navId) {
                openMenu(false);
                window.location.hash = '';
                if (window.history && window.history.replaceState) {
                    window.history.replaceState(null, document.title, `${window.location.pathname}${window.location.search}`);
                }
            }
        });
    };

    const destroy = () => {
        $menuToggle.off('click', onMenuToggle);
        $('body').off('keyup', onBodyKeyUp);
        Viewport.off('breakpoint', onBreakpoint);
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);
        $el.off('click focusin');
        Dispatch.off(MENU_OPEN, onMenuOpen);
        Dispatch.off(MENU_CLOSE, onMenuClosed);
    };

    return {
        init,
        destroy
    };
};
