import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';

import StickySidebar from 'sticky-sidebar';

import { DOM_UPDATED } from '../lib/events';

export default el => {
    const STICKY_ENABLE_BREAKPOINT = 980;

    const $el = $(el);
    const $stickyWrap = $el.find('[data-sticky-wrapper]');
    const $contentWrap = $el.find('[data-content-wrapper]');
    const $stickyItem = $el.find('[data-sticky-item]');

    let sticky = null;

    const createSticky = () => {
        if (sticky === null) {
            sticky = new StickySidebar($stickyWrap.get(0), {
                topSpacing: 75,
                bottomSpacing: 75,
                innerWrapperSelector: '.sticky-teaser'
            });
        } else {
            sticky.updateSticky();
        }
    };

    const killSticky = () => {
        if (sticky !== null) {
            sticky.destroy();
            sticky = null;
        }
    };

    const onBreakpoint = data => {
        if (sticky === null && data.detail.current.size >= STICKY_ENABLE_BREAKPOINT) {
            createSticky();
        }
        if (sticky !== null && data.detail.current.size < STICKY_ENABLE_BREAKPOINT) {
            killSticky();
        }
    };

    const onResize = e => {
        if (sticky !== null) {
            sticky.updateSticky();
        }
    };

    const onDomUpdated = e => {
        if (sticky !== null) {
            sticky.updateSticky();
        }
    };

    const init = () => {
        if (Viewport.breakpoint.size >= STICKY_ENABLE_BREAKPOINT) {
            createSticky();
        }

        Dispatch.on(DOM_UPDATED, onDomUpdated);
        Viewport.on('resize', onResize);
        Viewport.on('breakpoint', onBreakpoint);
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
        Viewport.off('breakpoint', onBreakpoint);
        killSticky();
    };

    return {
        init,
        destroy
    };
};
