import $ from '../core/Dom';
import gsap from 'gsap';

let $el;

const destroy = () => {

};

const init = (el, props) => {
    $el = $(el);

    gsap.to($el.get(0), 0.6, { delay: 1, y: 0, ease: 'back.out' });
    const hideTimeout = $el.hasClass('-error') ? 10000 : 5000;

    setTimeout(function () {
        gsap.to($el.get(0), 0.3, {
            y: -$el.height() - 40, ease: 'sine.in', onComplete: function () {
                $el.remove();
            }
        });
    }, hideTimeout);

};

export default {
    init,
    destroy
};
