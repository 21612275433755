import $ from '../core/Dom';

import { getAxios } from "../lib/helpers";
import { initCardElement, createPaymentMethod } from '../lib/stripeCardElementFacade';

let $form;
let $btn;
let $response;

let card;
let getPaymentIntentUrl;
let isSending = false;

const validateEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

const validateForm = () => {
    let validated = true;

    const $firstName = $form.find('#firstName');
    const $lastName = $form.find('#lastName');
    const $receiverEmail = $form.find('#receiverEmail');
    const $senderName = $form.find('#senderName');
    const $senderEmail = $form.find('#senderEmail');

    if ($firstName.val() === '') {
        validated = false;
        $firstName.addClass('has-error')
    }

    if ($lastName.val() === '') {
        validated = false;
        $lastName.addClass('has-error')
    }

    if (!validateEmail($receiverEmail.val())) {
        validated = false;
        $receiverEmail.addClass('has-error')
    }

    if ($senderName.val() === '') {
        validated = false;
        $senderName.addClass('has-error')
    }

    if (!validateEmail($senderEmail.val())) {
        validated = false;
        $senderEmail.addClass('has-error')
    }

    return validated;
};

const onCardChange = () => {
    //console.log('onCardChange');

};

const showError = message => {
    $response.text('En feil oppstod: ' + message).css({ color: '#f00', display: 'block' });
};

const destroy = () => {

};

const init = (el, props) => {
    $form = $(el).find('form');
    $btn = $form.find('[data-form-submit-button]');
    $response = $(el).find('[data-form-response]');
    
    getPaymentIntentUrl = props && props.getPaymentIntentUrl ? props.getPaymentIntentUrl : null;

    const $cardElement = $(el).find('#card-element');
    const $paymentRef = $(el).find('input[name="paymentRef"]');

    const { stripe } = window;

    // init stripe card element
    initCardElement($cardElement.get(0), {})
        .then(elementCard => {
            card = elementCard;
            card.addEventListener('change', onCardChange);
        })
        .catch(error => {
            showError(error.message || error);
            console.error(error);
        });

    // form submit handler
    $form.on('submit', e => {
        e.preventDefault();

        if (!isSending && validateForm()) {
            isSending = true;
            $btn.css({ opacity: 0.5 });

            getAxios()
                .post(getPaymentIntentUrl, { giftType: $form.find('input[name="type"]').get(0).value })
                .then(result => {
                    if (result.data && result.data.success) {
                        const clientSecret = result.data.clientSecret;

                        window.stripe.confirmCardPayment(clientSecret, {
                            payment_method: {
                                card: card,
                                billing_details: {
                                    name: $form.find('#senderName').val(),
                                    email: $form.find('#senderEmail').val()
                                }
                            }
                        }).then(function (result) {
                            console.log('then', result);
                            
                            if (result.error) {
                                isSending = false;
                                $btn.css({ opacity: 1 });
                                showError(result.error.message);
                            } else {
                                // The payment has been processed!
                                if (result.paymentIntent.status === 'succeeded') {
                                    $paymentRef.val(result.paymentIntent.id);
                                    $form.off('submit');
                                    $form.get(0).submit();
                                } else {
                                    isSending = false;
                                    $btn.css({ opacity: 1 });
                                    showError(result.error.message || result.error);
                                }
                            }
                        });
                    } else {
                        isSending = false;
                        $btn.css({ opacity: 1 });
                        showError('Betaling kunne ikke gjennomføres.');
                        
                    }
                })
                .catch(error => {
                    //console.error(error)
                    isSending = false;
                    $btn.css({ opacity: 1 });
                    showError(error.message || error);
                });
        }
    });
};

export default {
    init,
    destroy
};
