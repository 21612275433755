import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';
import { reduceMotion } from '../lib/helpers';

export default el => {

    const $el = $(el);

    let panelObserver;
    let storyObserver;

    let activePanel;
    let activeImage;
    let hasPreloaded = false;

    const setActiveImage = newActiveImage => {

        if (newActiveImage === activeImage) {
            return;
        }

        gsap.killTweensOf(newActiveImage);

        const tl = gsap.timeline();

        const reducedMotion = reduceMotion();

        const duration = reducedMotion ? 0.3 : 0.5;

        if (activeImage) {
            tl
                .to(activeImage, {
                    delay: duration,
                    opacity: 0,
                    duration: 0,
                    scale: 1
                }, 0);
        }

        tl.set(newActiveImage, { zIndex: 100 }, 0);

        if (!reducedMotion) {
            tl.fromTo(newActiveImage, { scale: 1.05 }, {
                scale: 1,
                duration: 1,
                ease: 'Quad.easeOut'
            }, 0);
        }

        tl.fromTo(newActiveImage, { opacity: 0 }, {
            opacity: 1,
            duration,
            onComplete() {
                gsap.set(newActiveImage, { clearProps: 'z-index' });
            }
        }, 0);

        activeImage = newActiveImage;

    };

    const setActivePanel = panel => {

        activePanel = panel;

        const { imageUid } = panel.dataset;

        const newActiveImage = (imageUid ? $el.find(`[data-image="${imageUid}"]`)
            .get(0) : null) || activeImage || $el.find('[data-image]')
            .get(0);

        setActiveImage(newActiveImage);
    };

    const onPanelObserve = entries => {
        let intersecting = [];
        entries.forEach(entry => {
            const {
                target,
                isIntersecting,
                intersectionRatio
            } = entry;
            if (!isIntersecting || (intersectionRatio < 0.5 && !!activePanel)) {
                return;
            }
            intersecting.push({
                target,
                intersectionRatio
            });
        });
        if (!intersecting.length) {
            return;
        }
        intersecting = intersecting.sort((a, b) => parseFloat(b.intersectionRatio) - parseFloat(a.intersectionRatio)).map(({ target }) => target);

        setActivePanel(intersecting.shift());
    };

    const onStoryObserve = entries => {
        console.log('onStoryObserve');
        let intersecting = [];
        entries.forEach(entry => {
            const {
                target,
                isIntersecting,
                intersectionRatio
            } = entry;
            if (!isIntersecting || (intersectionRatio < 0 && !!activePanel)) {
                return;
            }
            intersecting.push({
                target,
                intersectionRatio
            });
        });
        if (!intersecting.length) {
            return;
        }

        if (!hasPreloaded) {
            $el.find('img.lazyload').addClass('lazypreload');
            hasPreloaded = true;
            storyObserver.disconnect();
        }
    };
    
    const init = () => {
        panelObserver = new IntersectionObserver(onPanelObserve, {
            threshold: [0, 0.25, 0.5, 0.75, 1]
        });
        
        
        $el.find('[data-panel]').each(panel => {
            panelObserver.observe(panel);
        });
        
        storyObserver = new IntersectionObserver(onStoryObserve, {});
        storyObserver.observe(el);
        
        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        panelObserver.disconnect();
    };

    return {
        init,
        destroy
    };

};
