import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);

    const $image = $el.find('[data-image]');
    const $video = $el.find('[data-video]');
    const video = $video.get(0);
    const videoSource = $video.attr('data-video');

    let videoIsPlaying = false;
    let videoFallbackTimer = null;
    let videoAspectRatio = $image.width() / $image.height();
    let observer = null;
    
    const init = () => {
        if (!video.canPlayType || !video.canPlayType('video/mp4')) {
            cantPlayVideo();
            return;
        }

        if (!videoSource) {
            return;
        }

        killVideo();
        video.src = videoSource;
        $video.addClass('videoload');

        observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const { isIntersecting, intersectionRatio } = entry;
                
                if (isIntersecting) {
                    onIntersect();
                }
            });
        }, {
            threshold: [0]
        });

        observer.observe($el.get(0));

        Viewport.on('resize', onResize);
    };

    const onIntersect = () => {
        playAndCatch();
        observer.disconnect();
    };
    
    const destroy = () => {
        killVideo();
    };

    const onResize = () => {
        const wrapperWidth = $el.width();
        const wrapperHeight = $el.height();
        const wrapperAspect = wrapperWidth / wrapperHeight;
        
        updateVideoAspectRatio();

        if (videoAspectRatio > wrapperAspect) {
            $video.css({ height: `${wrapperHeight}px`, width: `${wrapperHeight * videoAspectRatio}px`, top: 0, left: `${(wrapperWidth - (wrapperHeight * videoAspectRatio)) / 2}px`, maxWidth: 'none' });
        } else {
            $video.css({ width: `${wrapperWidth}px`, height: `${wrapperWidth / videoAspectRatio}px`, left: 0, top: `${(wrapperHeight - (wrapperWidth / videoAspectRatio)) / 2}px`, maxWidth: 'none' });
        }
    };

    const updateVideoAspectRatio = () => {
        $video.css({ width: '', height: '' });
        videoAspectRatio = $video.width() / $video.height();
    };

    const stopFallbackTimer = () => {
        clearTimeout(videoFallbackTimer);
        videoFallbackTimer = null;
    };

    const startFallbackTimer = interval => {
        stopFallbackTimer();
        videoFallbackTimer = setTimeout(onFallbackTimer, interval);
    };

    const addVideoEventListeners = () => {
        video.addEventListener('timeupdate', onTimeUpdate);
        video.addEventListener('loadstart', onLoadStart);
        video.addEventListener('loadedmetadata', onLoadStart);
        video.addEventListener('loadeddata', onLoadStart);
        video.addEventListener('canplay', onLoadStart);
    };

    const removeVideoEventListeners = () => {
        video.removeEventListener('timeupdate', onTimeUpdate);
        video.removeEventListener('loadstart', onLoadStart);
        video.removeEventListener('loadedmetadata', onLoadStart);
        video.removeEventListener('loadeddata', onLoadStart);
        video.removeEventListener('canplay', onLoadStart);
    };

    const killVideo = () => {
        stopFallbackTimer();
        removeVideoEventListeners();
        video.pause();
        videoIsPlaying = false;
    };

    const playAndCatch = () => {
        addVideoEventListeners();
        video.muted = true;
        startFallbackTimer(1000);

        const promise = video.play();

        if (promise !== undefined) {
            promise.then(() => {
                stopFallbackTimer();
            }).catch(e => {
                if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                    cantPlayVideo();
                }
            });
        }
    };

    const cantPlayVideo = () => {
        killVideo();
        $image.addClass('lazyload');
        if ($video.length) {
            $video.remove();
        }
    };

    const onFallbackTimer = () => {
        cantPlayVideo();
    };

    const onTimeUpdate = () => {
        if (!video || video.currentTime < 0.001) {
            return;
        }
        onResize();
        removeVideoEventListeners();
        stopFallbackTimer();
        
        $video.addClass('is-playing').addClass('opacity-100');
        $video.removeClass('videoload').addClass('videoloaded');
        
        videoIsPlaying = true;
    };

    const onLoadStart = () => {
        onResize();
        if (videoIsPlaying) {
            return;
        }
        startFallbackTimer(6000);
    };

    return {
        init,
        destroy
    };
};
