import $ from '../core/Dom';

import Dispatch from '../core/Dispatch';
import { getAxios } from "../lib/helpers";

let $el;
let $btn;
let $receipt;

let sendEmailUrl;
let giftId;
let isSending = false;

const sendEmail = () => {
    isSending = true;
    $btn.css({ opacity: 0.5 });
    
    getAxios()
        .post(sendEmailUrl, {
            giftId: giftId
        })
        .then(result => {
            if (result.data && result.data.success) {
                $receipt.text('E-post sendt!').css({ display: 'block' });
            } else {
                console.error(result.data);
                $receipt.text('En feil oppstod ved sending av e-post!').css({ display: 'block', color: '#f00' });
            }
        })
        .catch(error => {
            console.error(error);
            $receipt.text('En feil oppstod ved sending av e-post!').css({ display: 'block', color: '#f00' });
        });
};

const destroy = () => {

};

const init = (el, props) => {
    $el = $(el);
    $receipt = $el.find('[data-send-email-response]');
    $btn = $el.find('[data-send-email-button]');
    sendEmailUrl = props.sendEmailUrl;
    giftId = props.giftId;
    
    $el.on('click', '[data-send-email-button]', () => {
        if (!isSending) {
            sendEmail();
        }
    });
};

export default {
    init,
    destroy
};
