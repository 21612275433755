import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import Bricks from 'bricks.js';

import { DOM_UPDATED, ARTICLE_LIST_UPDATED } from '../lib/events';

export default (el, props) => {
    const $el = $(el);

    let $items = $el.find('[data-item]');
    let bricks = null;

    const { layout } = props;

    const layoutSettings = {
        '2-only': [
            { columns: 2, gutter: 0 }
        ],
        '2-4': [
            { columns: 2, gutter: 0 },
            { mq: '980px', columns: 4, gutter: 0 }
        ]
    };

    const reset = () => {
        $el.css({ position: '', width: '', height: '' });
        $items.css({ position: '', top: '', left: '' });
    };
    
    const onArticleListUpdated = () => {
        reset();
        $items = $el.find('[data-item]');
        bricks.pack();
        Dispatch.emit(DOM_UPDATED);
    };
    
    const onResize = () => {
        reset();
        bricks.pack();
        Dispatch.emit(DOM_UPDATED);
    };

    const init = () => {
        bricks = Bricks({
            container: el,
            packed: 'data-packed',
            sizes: layoutSettings[layout]
        });

        bricks.pack();
        Dispatch.emit(DOM_UPDATED);
        
        Dispatch.on(ARTICLE_LIST_UPDATED, onArticleListUpdated);
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        reset();
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };
};
