import axios from 'axios';
import { stringify } from 'qs';

export const isTouch = () => !document.documentElement.classList.contains('using-mouse');

export const isLivePreview = () => document.documentElement.classList.contains('is-preview') && document.documentElement.classList.contains('is-iframe');

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export const reduceMotion = () => window.matchMedia('(prefers-reduced-motion: reduce), (update: slow)').matches;


/**
 * Returns an Axios instance with a sensible, default configuration
 * @param config
 * @returns {AxiosInstance}
 */
const { csrfTokenValue } = window;
export const getAxios = (config = {}) => axios.create({
    baseURL: window.location.href || '/',
    headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': csrfTokenValue
    },
    transformRequest: [(data) => stringify(data)],
    ...config
});
