import { getAxios } from "./helpers";
import Config from "../core/Config";

/**
 * Initialise a Stripe Elements Card on a DOM node
 *
 * @param node
 * @param opts
 * @returns {Promise<unknown>}
 */
export const initCardElement = (node, opts = {}) => new Promise((resolve, reject) => {
    const { id } = node;
    if (!id) {
        reject('Card element node has no ID');
        return;
    }

    const then = new Date().getTime();

    const createCardElement = () => {
        const { stripe } = window;
        if (!stripe) {
            if (new Date().getTime() - then < 5000) {
                requestAnimationFrame(createCardElement);
            } else {
                reject('Stripe.js not found');
            }
            return;
        }
        const elements = stripe.elements(opts.elements || {});
        const card = elements.create('card', {
            hidePostalCode: true,
            style: {
                base: {
                    fontSize: '16px',
                    fontFamily: 'courier, monospaced',
                    fontWeight: 400,
                    color: '#373336',
                    
                    fontSmoothing: 'antialiased',
                    ':-webkit-autofill': {
                        color: '#373336',
                    },
                    '::placeholder': {
                        color: '#373336',
                    },
                },
                invalid: {
                    iconColor: '#ff0000',
                    color: '#ff0000',
                }
            },
            ...(opts.card || {})
        });
        card.mount(`#${id}`);
        resolve(card);
    };

    createCardElement();

});

export const createPaymentMethod = (card, billingDetails) => new Promise((resolve, reject) => {

    if (!card) {
        reject('Invalid card element');
    }

    const { stripe } = window;

    stripe
        .createPaymentMethod({
            type: 'card',
            billing_details: billingDetails,
            card
        })
        .then(({ error, paymentMethod }) => {
            if (error) {
                reject(error);
                return;
            }
            resolve(paymentMethod.id);
        })
        .catch(error => {
            reject(error);
        });

});

/**
 *
 * @param card
 * @param billingDetails
 * @returns {Promise<unknown>}
 */
export const confirmCardSetup = (card, billingDetails) => new Promise((resolve, reject) => {

    if (!card) {
        reject('Invalid card element');
        return;
    }

    getAxios()
        .post(Config.get('setupIntentSecretEndpoint'))
        .then(({ data }) => data)
        .then(({ secret: setupIntentSecret }) => {
            // Confirm the SetupIntent with the card details
            const { stripe } = window;
            stripe
                .confirmCardSetup(setupIntentSecret, {
                    payment_method: {
                        card,
                        billing_details: billingDetails,
                    }
                })
                .then(({ error, setupIntent }) => {
                    if (error) {
                        reject(error);
                        return;
                    }
                    resolve(setupIntent.payment_method);
                })
                .catch(error => {
                    reject(error);
                });
        })
        .catch(error => {
            reject(error);
        });

});
