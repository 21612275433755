import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import gsap from 'gsap';

export default el => {
    const $el = $(el);
    const $imgWrap = $el.find('[data-image-wrap]');
    const $imgObj = $el.find('[data-image-obj]');

    let animationId = null;
    
    let elementHeight;
    let elementTop;
    let oversizeThreshold;

    const isActive = () => {
        const { scrollTop } = Viewport;
        return scrollTop > elementTop - elementHeight && scrollTop < elementTop + elementHeight;
    };
    
    const update = () => {
        const { scrollTop } = Viewport;

        gsap.set($imgObj.get(0), { y: (-oversizeThreshold * (1 - (elementTop - scrollTop + elementHeight) / (elementHeight * 2))) });
        
        animationId = requestAnimationFrame(update);
    };
    
    const start = () => {
        if (animationId !== null) {
            cancelAnimationFrame(animationId);
            animationId = null;
        }

        animationId = requestAnimationFrame(update);
    };

    const stop = () => {
        cancelAnimationFrame(animationId);
        animationId = null;
    };
    
    const onScroll = e => {
        if (isActive()) {
            if (animationId === null) {
                start();
            }
        } else {
            if (animationId !== null) {
                stop();
            }
        }
    };

    const onResize = e => {
        elementHeight = $el.height();
        elementTop = $el.offset().top;
        oversizeThreshold = $imgObj.height() - $imgWrap.height();

        update();
    };

    const init = () => {
        $imgObj.css({ top: 0 });
        
        onResize();

        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);
    };

    const destroy = () => {
        if (animationId !== null) {
            stop();
        }
        
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);
    };

    return {
        init,
        destroy
    };
};
