import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import request from '../core/request';

import { ARTICLE_LIST_UPDATED } from '../lib/events';

export default el => {
    const $el = $(el);
    const $list = $el.find('[data-list]');
    const $pagination = $el.find('[data-pagination-wrap]');

    const init = () => {
        $pagination.on('click', 'a', e => {
            e.preventDefault();
            loadNextPage();
        });
    };

    const destroy = () => {
        $pagination.off('click');
    };
    
    const loadNextPage = () => {
        const $a = $pagination.find('a');
        const url = $a.attr('href');
        $pagination.find('[data-error]').attr('hidden', '');
        
        $a.find('span:first-child').css('opacity', '0');
        $a.find('span:last-child').removeClass('hidden').addClass('block');
            
        request
           .get(url)
           .then(res => {
               if (res.statusCode === 200) {
                   parseData(res.text);
               } else {
                   $pagination.find('[data-error]').attr('hidden', null);
               }

                $a.find('span:first-child').css('opacity', '1');
                $a.find('span:last-child').removeClass('block').addClass('hidden');
           });
    };
    
    const parseData = data => {
        const $data = $('<div>' + data + '</div>');
        const $newItems = $data.find('[data-item]');
        const $newPagination = $data.find('[data-pagination-wrap]');

        $list.append($newItems);
        $pagination.empty().append($newPagination.nodes);
        
        Dispatch.emit(ARTICLE_LIST_UPDATED, { $newItems });
    };

    return {
        init,
        destroy
    };
};
